<template>
  <div class="default-profile-picture flex-center rounded-full bg-neutral-50 border-2 text-neutral-400 w-full h-full font-bold">
    {{ capitalizeWords(user?.firstName?.[0] || '') }}{{ capitalizeWords(user?.lastName?.[0] || '') }}
  </div>
</template>

<script setup lang="ts">
const auth = useAuthStore()
const user = computed(() => auth.shopify.customer)
</script>

<style>
.w-24 .default-profile-picture {
  font-size: 40px;
}
.w-36 .default-profile-picture {
  font-size: 60px;
}
</style>
