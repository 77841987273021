<template>
  <div class="profile-picture-wrapper relative aspect-square flex-center shrink-0 rounded-full bg-neutral-200 outline outline-2 -outline-offset-2 outline-white/50 group overflow-hidden">
    <SkeletonLoader
      v-if="store.loading"
      class="w-full h-full grow opacity-50"
    />
    <div v-else-if="picture">
      <img
        :src="picture"
        alt="Profile Picture"
        class="profile-picture"
      >
    </div>
    <DefaultProfilePicture v-else />
  </div>
</template>

<script setup lang="ts">
const store = supabaseStores.useProfileStore()
const picture = store.usePicture()
</script>
